<template>
  <div class="overlay-main"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.overlay-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
</style>
